<template>
    <v-card min-height="500px">
        <v-card-text>
            <ValidationObserver ref="observer" v-slot="{}">
                <v-form>
                    <!-- Password Management Section -->
                    <v-row dense>
                        <v-col cols="12" sm="6">
                            <CustomLabel
                                for="new_password"
                                label="New Password"
                                required
                            />
                            <CustomTextField
                                id="new_password"
                                inputIcon="mdi-lock"
                                inputType="password"
                                inputPlaceholder="New Password"
                                :inputValue="credentials.new_password"
                                validationName="new_password"
                                validationRules="required|password:@confirm_password"
                                @input="updateLocalForm($event, 'credentials')"
                            />
                        </v-col>

                        <v-col cols="12" sm="6">
                            <CustomLabel
                                for="confirm_password"
                                label="Confirm Password"
                                required
                            />
                            <CustomTextField
                                id="confirm_password"
                                inputIcon="mdi-lock"
                                inputType="password"
                                inputPlaceholder="Confirm Password"
                                :inputValue="credentials.confirm_password"
                                validationName="confirm_password"
                                validationRules="required|password:@new_password"
                                @input="updateLocalForm($event, 'credentials')"
                            />
                        </v-col>
                    </v-row>

                    <!-- Divider -->
                    <v-row>
                        <v-col>
                            <v-divider />
                        </v-col>
                    </v-row>

                    <!-- Status & Access Section -->
                    <v-row>
                        <v-col>
                            <CustomLabel label="Status &amp; Access" />

                            <div class="avsb-description-col-text">
                                <span> User account role and status </span>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col>
                            <CustomLabel for="role" label="Role" />
                            <CustomSelectField
                                id="role"
                                inputIcon="mdi-account-cog"
                                inputPlaceholder="Role"
                                :optionItems="userRoles"
                                optionText="type"
                                optionValue="id"
                                @input.native="updateLocalForm($event)"
                            />
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col>
                            <v-row dense>
                                <v-col>
                                    <v-switch
                                        @change="toggleStatus"
                                        inset
                                        v-model="status"
                                        :label="`Account Status: ${
                                            status ? 'Enabled' : 'Disabled'
                                        }`"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <!-- Button Sections -->
                    <v-row>
                        <v-col>
                            <CustomButton
                                btnLabel="Cancel"
                                tooltipMsg="Cancel"
                                @click="hideDialog"
                            />
                            <CustomButton
                                btnColour="primary"
                                btnLabel="Save"
                                tooltipMsg="Save"
                                @click="updatePassword(user, credentials)"
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </ValidationObserver>
        </v-card-text>
    </v-card>
</template>


<script>
import { mapState } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { userRoles } from "@/services/OptionMappers";

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});

export default {
    computed: {
        ...mapState("usersAdminMgmt", ["user"]),
    },
    data: () => ({
        credentials: {
            new_password: null,
            confirm_password: null,
        },
        userRoles: userRoles,
        status: true,
    }),
    methods: {
        hideDialog() {
            this.$store.commit("usersAdminMgmt/HIDE_VIEW_OPS_DIALOG");
        },
        updatePassword(payload, credentials) {
            this.$store.dispatch("usersAdminMgmt/updateItemPassword", {
                id: payload.id,
                credentials: credentials,
            });

            this.credentials.new_password = null;
            this.credentials.confirm_password = null;
            this.$refs.observer.reset();
        },
        updateLocalForm(event, form, field) {
            if (event instanceof InputEvent) {
                this[form][event.target.id] = event.target.value;
            } else if (
                event instanceof String ||
                typeof event == "boolean" ||
                typeof event == "number" ||
                typeof event == "string"
            ) {
                this[form][field] = event;
            }
        },
        toggleStatus() {},
    },
    name: "TabUserSecurity",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>